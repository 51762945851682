<template>
  <div>
    <app-main>
      <template v-slot:main-top>
        <!-- 查询 -->
        <div class="query-header">
          <el-form label-width="80px" :model="form">
            <el-row>
              <el-col :span="6">
                <el-form-item label="T单号">
                  <el-input v-model="form.order" class="input" placeholder="T单号"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="来源平台">
                  <el-select class="input" placeholder="请选择" v-model="form.value" clearable @change="selectChange" @clear="selectClear">
                    <el-option v-for="(item, index) in options" :key="index" :value="item"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="6">
                <el-form-item label="备注">
                  <el-input v-model="form.msg" class="input" placeholder="备注信息"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="查询时间">
                  <el-date-picker
                    class="input"
                    @change="dateChange"
                    v-model="form.times"
                    format="yyyy-MM-dd" value-format="yyyy-MM-dd"
                    type="daterange"
                    range-separator="~"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期">
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item>
                  <div class="date-filter">
                    <span :class="{'active': spanIndex === index}" @click="dateClick(index)" v-for="(item, index) in dateSelection" :key="index">{{item}}</span>
                  </div>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item>
                  <el-button type="primary" size="small" @click="queryClick">查询</el-button>
                  <el-button size="small" @click="resetClick">重置</el-button>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </template>
      <template v-slot:main-content>
        <div>
          <data-statistics :statusNum="statusNum"/>
          <order-table @menuChange="menuChange" @curChange="curChange" :orderData="orderData" :activeIndex="activeIndex" @remove="remove"/>
        </div>
      </template>
    </app-main>
  </div>
</template>

<script>
import AppMain from 'components/baseStructure/AppMain'
import DataStatistics from './childComps/DataStatistics'
import OrderTable from './childComps/OrderTable'
import { getOrderList, changeOrderStatus, statusNum } from 'network/api'

export default {
  inject: ['mainReload'],
  components: {
    AppMain,
    DataStatistics,
    OrderTable
  },
  data () {
    return {
      spanIndex: 0,
      options: ['淘宝/天猫', '阿里巴巴', '拼多多', '京东', '抖音', '其他'],
      dateSelection: ['今天', '昨天', '最近7天', '最近30天'],
      form: {
        value: '', // 平台
        times: [], // 选择时间
        msg: '', // 备注
        order: '' // 单号
      },
      orderData: {},
      platform: '', // 筛选平台参数
      activeIndex: '0', // 筛选状态参数
      currentPage: '1', // 当前页
      statusNum: {}
    }
  },
  created () {
    // 初始化时间选择器
    const dayStar = new Date()
    const dayEnd = new Date()
    dayStar.setTime(dayStar.getTime())
    dayEnd.setTime(dayEnd.getTime() + 24 * 3600 * 1000)
    this.form.times = [dayStar.getFullYear() + '-' + (dayStar.getMonth() + 1) + '-' + dayStar.getDate(), dayEnd.getFullYear() + '-' + (dayEnd.getMonth() + 1) + '-' + dayEnd.getDate()]
    // 初始化订单列表
    this.getList()
    // 初始化子订单数量状态
    this.getStatusNum({ createtime: this.form.times })
  },
  methods: {
    // 平台改变
    selectChange (val) {
      switch (val) {
        case '淘宝/天猫':
          this.platform = 'is_tb'
          break
        case '阿里巴巴':
          this.platform = 'is_ali'
          break
        case '拼多多':
          this.platform = 'is_pinxixi'
          break
        case '京东':
          this.platform = 'is_jd'
          break
        case '抖音':
          this.platform = 'is_dy'
          break
        case '其他':
          this.platform = 'is_others'
          break
      }
    },
    // 清除选择平台
    selectClear () {
      this.platform = ''
    },
    // 筛选订单列表请求
    getList () {
      getOrderList({
        order_status: this.activeIndex, // 订单状态
        order_no: this.form.order, // 订单单号
        createtime: this.form.times, // 时间
        order_tips: this.form.msg, // 备注
        platform: this.platform, // 平台
        page: this.currentPage // 当前页
      }).then(res => {
        this.orderData = res.data
      })
    },
    // 获取不同状态子订单数量
    getStatusNum (params) {
      statusNum(params).then(res => {
        this.statusNum = res.data
      })
    },
    // menu改变
    menuChange (index) {
      this.activeIndex = index
      this.currentPage = '1'
      switch (index) {
        // 全部
        case '0':
          this.getList()
          break
        // 待支付
        case '1':
          this.getList()
          break
        // 打单中
        case '2':
          this.getList()
          break
        // 已出单
        case '3':
          this.getList()
          break
        // 异常
        case '4':
          this.getList()
          break
        // 取消
        case '5':
          this.getList()
          break
      }
    },
    // 分页改变
    curChange (val) {
      this.currentPage = val
      this.getList()
    },
    // 选择时间改变
    dateChange (val) {
      this.form.times = val
      this.spanIndex = false
      // 查询
      this.getList()
      this.getStatusNum({ createtime: this.form.times })
    },
    // 日期点击
    dateClick (index) {
      this.spanIndex = index
      var dayStar = new Date()
      var dayEnd = new Date()
      switch (index) {
        // 今天
        case 0:
          dayStar.setTime(dayStar.getTime())
          dayEnd.setTime(dayEnd.getTime() + 24 * 3600 * 1000)
          this.form.times = [dayStar.getFullYear() + '-' + (dayStar.getMonth() + 1) + '-' + dayStar.getDate(), dayEnd.getFullYear() + '-' + (dayEnd.getMonth() + 1) + '-' + dayEnd.getDate()]
          this.getList()
          this.getStatusNum({ createtime: this.form.times })
          break
        // 昨天
        case 1:
          dayStar.setTime(dayStar.getTime() - 24 * 3600 * 1000)
          dayEnd.setTime(dayEnd.getTime())
          this.form.times = [dayStar.getFullYear() + '-' + (dayStar.getMonth() + 1) + '-' + dayStar.getDate(), dayEnd.getFullYear() + '-' + (dayEnd.getMonth() + 1) + '-' + dayEnd.getDate()]
          this.getList()
          this.getStatusNum({ createtime: this.form.times })
          break
        // 最近7天
        case 2:
          dayStar.setTime(dayStar.getTime() - 7 * 24 * 3600 * 1000)
          dayEnd.setTime(dayEnd.getTime() + 24 * 3600 * 1000)
          this.form.times = [dayStar.getFullYear() + '-' + (dayStar.getMonth() + 1) + '-' + dayStar.getDate(), dayEnd.getFullYear() + '-' + (dayEnd.getMonth() + 1) + '-' + dayEnd.getDate()]
          this.getList()
          this.getStatusNum({ createtime: this.form.times })
          break
        // 最近30天
        case 3:
          dayStar.setTime(dayStar.getTime() - 30 * 24 * 3600 * 1000)
          dayEnd.setTime(dayEnd.getTime() + 24 * 3600 * 1000)
          this.form.times = [dayStar.getFullYear() + '-' + (dayStar.getMonth() + 1) + '-' + dayStar.getDate(), dayEnd.getFullYear() + '-' + (dayEnd.getMonth() + 1) + '-' + dayEnd.getDate()]
          this.getList()
          this.getStatusNum({ createtime: this.form.times })
          break
      }
    },
    // 查询
    queryClick () {
      this.getList()
      this.getStatusNum({ createtime: this.form.times })
    },
    // 重置
    resetClick () {
      this.mainReload()
    },
    // 取消订单
    remove (id) {
      changeOrderStatus({ id: id, order_status: '5' }).then(res => {
        this.getList()
        this.getStatusNum({ createtime: this.form.times })
        this.$message({
          message: '成功',
          type: 'success'
        })
      })
    }
  }
}
</script>
<style scoped>
.input[data-v-8fa9cf56]{
  width: 80% !important;
}
</style>
<style scoped src="styles/components/queryHeader.css">

</style>
